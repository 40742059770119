<template>
    <div class="rules">
        <h5header :name="$t('reward.desc17')" :right="false" />
        <div class="rules-list">
            <div class="list-desc"
                v-html="$t('reward.desc147', { n1: configInfo.activityParamOne * 100, n2: configInfo.activityParamThree * 100, n3: configInfo.activityParamTwo * 100 })">
            </div>
            <div class="list-desc">{{ $t('reward.desc148') }}<label>{{ common.formatDate(configInfo.startTime) }} - {{
                common.formatDate(configInfo.endTime) }}(GST)</label> </div>
            <div class="list-desc">{{ $t('reward.desc149') }}</div>
            <div class="list-desc">{{ $t('reward.desc150') }}</div>
            <div class="list-desc">{{ $t('reward.desc151') }}</div>

            <div class="table-info" v-for="item in issueList" :key="item.num">
                <div class="info flex">
                    <div>{{ $t('reward.desc152') }}</div>
                    <p>{{ $t('reward.desc153', { n1: item.num }) }}</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc141') }}</div>
                    <p>{{ item.starttime }} {{ $t('reward.desc160') }} {{ item.endtime }} (GST)</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc159') }}</div>
                    <p>{{ item.sendtime }}</p>
                </div>
            </div>
            <div class="list-desc">{{ $t('reward.desc154') }}</div>

            <div class="table-info">
                <div class="info flex">
                    <div>{{ $t('reward.desc141') }}</div>
                    <p>2023-11-24 11:00:00-2024-01-21 23:59:59  (GST)</p>
                </div>

                <div class="info flex">
                    <div>{{ $t('reward.desc159') }}</div>
                    <p>2024-01-22 02:00</p>
                </div>
            </div>
            <div class="list-desc">{{ $t('reward.desc155') }}<label>{{ configInfo.awardCoinName }}</label></div>
            <div class="list-desc">{{ $t('reward.desc156') }}{{ configInfo.awardAccountTypeName }}</div>
            <div class="list-desc">{{ $t('reward.desc157') }}</div>
            <div class="list-desc">{{ $t('reward.desc158') }}</div>
        </div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            issueList: [
            {
                    num: 1,
                    starttime: '2023-12-23 11:00:00',
                    endtime: '2023-12-24 23:59:59',
                    sendtime: '2023-12-25 02:00:00'
                },
                {
                    num: 2,
                    starttime: '2023-12-25 00:00:00',
                    endtime: '2024-12-31 23:59:59',
                    sendtime: '2024-01-01 02:00:00'
                },
                {
                    num: 3,
                    starttime: '2024-01-01 00:00:00',
                    endtime: '2024-01-07 23:59:59',
                    sendtime: '2024-01-08 02:00:00'
                },
                {
                    num: 4,
                    starttime: '2024-01-08 00:00:00',
                    endtime: '2024-01-14 23:59:59',
                    sendtime: '2024-01-15 02:00:00'
                },
                {
                    num: 5,
                    starttime: '2024-01-15 00:00:00',
                    endtime: '2024-01-21 23:59:59',
                    sendtime: '2024-01-22 02:00:00'
                }
            ],
            configInfo: {}
        }
    },
    mounted() {
        this.$post(this.URL.reward.land, {
            activityType: 2
        }).then(res => {
            if (res.code == 0) {
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo = res.data;
            }
        })
    }
}
</script>

<style lang="less" scoped>
.rules {
    padding: 56px 16px;

    .rules-list {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);

        .list-desc {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                color: #C9FA5B;
            }
        }

        .table-info {
            margin-bottom: 10px;
            padding: 16px;
            border-radius: 8px;
            background: #181818;
            .info{
                margin-bottom: 16px;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.6);
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    flex: 0 0 40%;
                }
                p{
                    flex: 0 0 60%;
                    text-align: right;
                    color: #fff;
                }
            }
        }
    }
}
</style>